<template>
  <v-row
    no-gutters
    class="h-100"
  >
    <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center text-center">
      <v-img
        :aspect-ratio="16/9"
        width="50%"
        contain
        :src="require('@/assets/images/errors/404.svg')"
      ></v-img>
      <h1 class="text-h5 mb-4">{{ $t('errorPage.title500') }}</h1>
      <p class="mb-4 font-weight-light">{{ $t('errorPage.subtitle500') }}</p>
      <v-btn
        color="primary"
        @click="$router.go(-1)"
        depressed
      >
        <v-icon
          right
          dark
          class="mr-2"
        >
          mdi-arrow-left
        </v-icon>
        {{ $t('errorPage.back') }}
      </v-btn>
      <p class="caption mt-2">ID: {{ traceId }}</p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Error500',
  props: ['traceId']
}
</script>
